import React from "react";
import { Layer } from "react-konva";
import URLImageElement from "../Elements/URLImageElement";
import Konva from "konva";

const SingleImageLayer = ({
	element,
	selectedImage,
	scale = 1,
	exportLayer = true,
	allowDefaultImagesToMove = false,
	setImageRef = null,
	filters = [],
}) => {
	const image = selectedImage ?? element.default_image.asset;
	return (
		<Layer className={!exportLayer ? "myera-layer-ignore" : ""}>
			<URLImageElement
				filters={filters}
				x={element.x}
				y={element.y}
				width={element.width * scale}
				height={element.height * scale}
				rotation={element.rotation}
				id={element.id}
				image={image}
				onSelect={() => {}}
				onChange={(new_ele) => {}}
				isSelected={false}
				onTransformEnd={() => {}}
				draggable={
					element.is_movable &&
					(allowDefaultImagesToMove || !image.startsWith("http"))
				}
				listening={element.is_movable}
				rotatable={element.is_rotatable}
				updateElementRefs={(ref) => {}}
				setImageRef={setImageRef}
			></URLImageElement>
		</Layer>
	);
};

export default SingleImageLayer;
