import { Divider, Slider } from "@mui/material";
import { toast } from "react-toastify";
import React, { useState } from "react";
import styles from "../Common/Tabs/tabs.module.css";
import bakingoStyles from "./Bakingo.module.css";
import { CloudUploadRounded, SearchRounded } from "@material-ui/icons";
import ImageUploadModal from "../Common/ImageUploadModal/ImageUploadModal";
import ImageCaptureModal from "../Common/ImageCaptureModal/ImageCaptureModal";
import OptionDialog from "../Common/OptionDialog/OptionDialog";
import {
	CheckCircleRounded,
	CloudUploadOutlined,
	ZoomOutMap,
} from "@mui/icons-material";
import ga4 from "react-ga4";
const GTMContainerId = "G-8CFQ42GSBS";

const SingleImageTab = ({
	onSelectImage,
	selectedImage,
	images,
	isUploadable,
	setUploadedImages,
	uploadedImages,
	scale,
	onScaleChange,
	allowCameraUpload,
	guideline,
	productName,
	onUploadDelete,
	exportImage = true,
	showResultCount = true,
}) => {
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [isCaptureModalOpen, setIsCaptureModalOpen] = useState(false);
	const [showOptionDialog, setShowOptionDialog] = useState(false);

	const [scriptsLoaded, setScriptsLoaded] = useState(false);
	function isFunctionDefined(functionName) {
		return typeof window[functionName] === "function";
	}

	function onCropperSave(images) {
		setUploadedImages(images);
		setIsUploadModalOpen(false);
	}

	const handleUploadClick = () => {
		if (isFunctionDefined("gtagBakingo")) {
			setScriptsLoaded(true);
		}
		let DTO = {
			screen_name: productName,
			page_location: window.location.href,
			button_name: "upload_image",
			heading: "upload_image_clicked",
		};
		window.parent.postMessage(
			{
				func: "fireGtagEvent",
				message: {
					eventName: "button_click",
					eventObject: DTO,
				},
			},
			"*"
		);

		ga4.event("button_click", DTO);

		if (allowCameraUpload) {
			setShowOptionDialog(true);
		} else {
			document.querySelector("#myera__script__upload__button").click();
		}
	};

	const handleCaptureSubmit = (image) => {
		setUploadedImages([image]);
	};

	function validateUploadFileType(file) {
		const validTypes = ["image/jpg", "image/jpeg", "image/png"];
		if (validTypes.includes(file.type)) {
			return true;
		} else {
			toast.error(
				"File should only be of type image/jpg, image/jpeg, image/png"
			);
			return false;
		}
	}
	const validateUploadFileSize = (file) => {
		const size = file.size / 1024 / 1024;
		if (size > 10) {
			toast.error("File size should be less than 10mb");
			return false;
		}
		if (size < 0.1) {
			toast.error("File size should be greater than 100kb");
			return false;
		}
		return true;
	};

	return (
		<div
			className={styles.tab_container}
			style={{
				paddingTop: "28px",
				paddingBottom: "28px",
			}}
		>
			{isUploadable && uploadedImages.length === 0 && (
				<input
					type="file"
					id="myera__script__upload__button"
					hidden
					accept="image/jpg, image/jpeg, image/png"
					onChange={(e) => {
						const files = e.target.files;
						const newFiles = [];
						let modalToOpen = true;
						for (let file of files) {
							if (
								validateUploadFileType(file) &&
								validateUploadFileSize(file)
							) {
								let ulr_obj = URL.createObjectURL(file);
								let fileWithExtension = {
									url: ulr_obj,
									name: file.name,
									extension: "jpeg",
								};
								newFiles.push(ulr_obj);
								console.log(ulr_obj);
							} else {
								modalToOpen = modalToOpen && false;
							}
						}
						setUploadedImages([...uploadedImages, ...newFiles]);
						if (modalToOpen && newFiles.length > 0) {
							setIsUploadModalOpen(true);
						}
					}}
				/>
			)}
			{isUploadable && uploadedImages.length === 0 && (
				<div className={[bakingoStyles.upload_container].join(" ")}>
					<div
						className={bakingoStyles.upload_graphic}
						onClick={handleUploadClick}
					>
						<CloudUploadOutlined />
						<div className={bakingoStyles.upload_text}>Upload Image</div>
					</div>
					<span className={bakingoStyles.upload_description}>
						File size should be 100kb to 10mb. Only png & jpg images.
					</span>
				</div>
			)}

			<div className={styles.image_list}>
				{uploadedImages.map((image) => (
					<div className={bakingoStyles.upload_tile}>
						<img
							src={image}
							className={bakingoStyles.upload_tile_image}
							alt="Uploaded Img"
						/>
						<div className={bakingoStyles.upload_tile_saved}>
							<CheckCircleRounded />
							Saved
						</div>
						<div
							className={bakingoStyles.upload_delete}
							onClick={() => {
								setUploadedImages(
									uploadedImages.filter((img) => img !== image)
								);
								onUploadDelete();
							}}
						>
							&times;
						</div>
					</div>
				))}
			</div>
			{uploadedImages.length > 0 && (
				<div className={styles.slider_container}>
					<ZoomOutMap
						style={{
							marginRight: "8px",
						}}
					/>
					Resize
					<Slider
						disabled={
							uploadedImages.includes(selectedImage.image) ? false : true
						}
						sx={{
							marginLeft: "12px",
							root: {
								color: "var(--myera-script-primary-color)",
							},
							"& .MuiSlider-thumb": {
								backgroundColor: "var(--myera-script-primary-color)",
								border: "2px solid var(--myera-script-primary-color)",
							},
							"& .MuiSlider-track": {
								backgroundColor: "var(--myera-script-primary-color)",
							},
							"& .MuiSlider-rail": {
								backgroundColor: "var(--myera-script-primary-color)",
							},
							"&.Mui-disabled": {
								color: "#bdbdbd",
								"& .MuiSlider-thumb": {
									backgroundColor: "#bdbdbd",
									border: "2px solid #bdbdbd",
								},
								"& .MuiSlider-track": {
									backgroundColor: "#bdbdbd",
								},
								"& .MuiSlider-rail": {
									backgroundColor: "#bdbdbd",
								},
							},
						}}
						value={scale}
						onChange={(e, val) => {
							onScaleChange(val);
						}}
						min={0.5}
						max={1.5}
						step={0.05}
					/>
				</div>
			)}
			<ImageUploadModal
				isOpen={isUploadModalOpen}
				setIsOpen={setIsUploadModalOpen}
				images={uploadedImages}
				state={"cropper"}
				onCropperSave={onCropperSave}
			></ImageUploadModal>
			<ImageCaptureModal
				isOpen={isCaptureModalOpen}
				setIsOpen={setIsCaptureModalOpen}
				guideline={guideline}
				onSubmit={handleCaptureSubmit}
			/>
			<OptionDialog
				open={showOptionDialog}
				onClose={() => {
					setShowOptionDialog(false);
				}}
				onCaptureFromCamera={() => {
					setShowOptionDialog(false);
					setIsCaptureModalOpen(true);
				}}
				onUploadFromGallery={() => {
					setShowOptionDialog(false);
					document.querySelector("#myera__script__upload__button").click();
				}}
			/>
		</div>
	);
};

export default SingleImageTab;
