import React, { useRef, useState } from "react";
import { Stage as KonvaStage } from "react-konva";
import styles from "./Stage.module.css";

const Stage = ({ layers, stageRef, enableInlineZoom = true }) => {
	const [selectedElement, setSelectedElement] = useState(false);

	const checkDeselect = (e) => {
		const clickedOnEmpty = e.target === e.target.getStage();
		if (clickedOnEmpty) {
			setSelectedElement(null);
		}
	};

	let stage = null;

	const handleMouseMove = (e) => {
		const mousePos = stage.getPointerPosition();
		const scale = 1.5; // your zoom scale
		stage.scale({ x: scale, y: scale });
		stage.position({
			x: -mousePos.x * (scale - 1),
			y: -mousePos.y * (scale - 1),
		});
		stage.batchDraw();
	};

	const handleMouseLeave = () => {
		stage.to({
			x: 0,
			y: 0,
			scaleX: 1,
			scaleY: 1,
			duration: 0.3, // duration in seconds
		});
	};

	return (
		<KonvaStage
			width={500}
			height={500}
			ref={(ref) => {
				stageRef(ref);
				stage = ref;
			}}
			className={styles.stage}
			onMouseDown={checkDeselect}
			onTouchStart={checkDeselect}
			onMouseMove={enableInlineZoom ? handleMouseMove : null}
			onMouseLeave={enableInlineZoom ? handleMouseLeave : null}
			onTouchMove={enableInlineZoom ? handleMouseMove : null}
			onTouchEnd={enableInlineZoom ? handleMouseLeave : null}
		>
			{layers}
		</KonvaStage>
	);
};

export default Stage;
